import React from "react"

import "../styles/global.css"

import Navbar from "../components/Navbar"
import MobileNavbar from "../components/MobileNavbar"
import Footer from "../components/Footer"
import ContactFooter from "../components/ContactFooter"
import Whatsapp from "../components/Whatsapp"

import Seo from "../components/seo"

const Page404 = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <h1 className="uppercase text-text-primary text-3xl">404, Uh Oh.</h1>
      <a href="/" className="text-text-primary text-xl underline">
        Take me home.
      </a>
    </div>
  )
}

export default function () {
  return (
    <>
      <Seo title="404" />
      <Whatsapp />
      <div className="w-full flex flex-col justify-center items-center font-helvetica">
        <div className="md:hidden w-full flex flex-col justify-center items-center font-helvetica">
          <MobileNavbar>
            <Page404 />
            <Footer />
            <ContactFooter />
          </MobileNavbar>
        </div>
        <div className="md:visible md:w-full md:flex md:flex-col md:justify-center md:items-center md:font-helvetica hidden">
          <Navbar />
          <Page404 />
          <Footer />
          <ContactFooter />
        </div>
      </div>
    </>
  )
}
